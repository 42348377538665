
.form-icon {
  position: absolute;
  top: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-icon > * {
  padding-left: 34px;
}

.position-relative {
  position: relative;
}
