
@import "sass/site/_colours.scss";

:deep(.p-inputtextarea) {
  color: $input-text-color;
}

.form-control-readonly {
  color: $input-readonly-text-color;
}
