
@import "sass/site/_colours.scss";

:deep(.p-autocomplete input.p-inputtext) {
  color: $input-text-color;
}

:deep(.has-icon .p-autocomplete input.p-inputtext) {
  padding-left: 0;
}
