
@import "sass/site/_colours.scss";
.dual-listbox-filter-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.p-picklist-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icons-container {
    transform: translateY(3px);
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .item-checkbox {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
  }
}
