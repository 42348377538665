
// The lightest colour on a white background that meets WCAG AA colour contrast requirements
$light-text-color: #757575;

// The lightest colour on a grey background
$light-text-color-on-gray: #707070;

// The green used for success / buttons is darker to ensure contrast between foreground and background colors meets WCAG AA
$brand-success: #2C882C !default;

$icon-with-text-color: #888888;

$background-light-grey: #f1f1f1;

$hover-list-item-color: #C3DAED;

$selected-list-item-color: #619FD0;

$selected-list-item-background-color: #F6F9FB;

// Standard Bootstrap error color.
$validation-error-color: #a94442;

// Standard Bootstrap warning alert colors
$alert-warning-text-color: #856404;
$alert-warning-background-color: #fff3cd;
$alert-warning-border-color: #ffeeba;

$input-focus-box-shadow: inset 0 1px 1px rgba(114, 80, 80, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
$input-error-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
$input-focus-border-color: #66afe9;
$input-error-border-color: #843534;
$input-border-color: #EEE;
$input-text-color: #555555;
$input-readonly-text-color: #959595;

$btn-default-hover-border:   #adadad;
$btn-default-hover-background:   #e6e6e6;

$dual-list-transfer-button-color: #333;
$dual-list-transfer-button-background-color: #fff;
$dual-list-transfer-button-border-color: #ccc;
$dual-list-transfer-button-background-color-enabled: #f0f0f0;
$dual-list-transfer-button-background-color-disabled: #fff;
$dual-list-transfer-button-background-color-hovered: #C3DAED;