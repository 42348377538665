
.table-header-summary {
  flex-basis: fit-content;
}

.table-header-selector {
  flex-grow: 1;
}

.uploads-table-label {
  margin-bottom: 0;
  margin-top: 0;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.table-header-button {
  margin-left: 1em;
}

.table-header-sort {
  padding-top: 5px;
}

@media screen and (max-width: 700px) {
  .table-header {
    flex-wrap: wrap;
  }

  .table-header-summary {
    flex-basis: 100%;
  }

  .table-header-button {
    margin-left: 0;
  }
}

@media screen and (max-width: 1500px) {
  .small-screen-hidden {
    display: none;
  }
}
