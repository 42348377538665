
@import "sass/site/_variables.scss";

.with-space {
  margin-right: 5px;
}

div[disabled="true"] {
  pointer-events: none;
  opacity: 0.7
}

.listbox-container {
  margin-bottom: 19px;
}
